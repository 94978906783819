import React, { Fragment } from 'react';

const ApplicationTile = ({ appIcon = null, appUrl, appTitle = 'Application Title', isAdmin = false, guide = false, guideUrl='' }) => {
    function setAdminIcon(isAdmin) {
        if (isAdmin === true) {
            // return (
            //     <Fragment>
            //         <Link className="link-tile" to="/sponsorUser" title="Sponsor a user" data-test="sponsor-icon" >
            //             <div className="link-tile-title">Sponsor User</div>
            //             {getSvgIcon('SponsorIcon', '', '40', '40', [colors.logoThemeBlackTransparent, colors.primaryWhite])}    
            //         </Link>
            //         {/* <br/> */}
            //         <Link className="link-tile application-tile-admin-link" to="/" title="Administrator" data-test="admin-icon">
            //             <div className="link-tile-title">Administrator</div>
            //             {getSvgIcon('AdminIcon', '', '40', '40', [colors.logoThemeBlackTransparent, colors.primaryWhite])}
            //         </Link>
            //     </Fragment>
            // );
        } else {
            return null;
        }
    }
    
    return (
        <div className="application-tile-body">
            <a href={appUrl} target="_blank" rel="noopener noreferrer">
                <div className="application-tile-icon">
                    {appIcon}
                </div>
            </a>
            <Fragment>
                <a className="tile-title" href={appUrl} target="_blank" rel="noopener noreferrer">
                    <div>{appTitle}</div>
                </a>
                <div className="application-tile-admin-icon">
                    {setAdminIcon(isAdmin)}
                </div>
            </Fragment>
            {
                guide &&
                <div className="application-tile-guide-url"><a href={guideUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#ffffff' }}>Guide</a></div>
            }
        </div>
    );
}

export default ApplicationTile;