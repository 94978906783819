import React, { useEffect } from 'react';
import Seal from '../images/SEAL.png'
import { useNavigate } from 'react-router-dom';
// import { useAuth } from 'oidc-react';
import { Button } from '../components/ui';
import { useGetAppSettings } from '../hooks';
import { Parser } from 'html-to-react';

const Login = () => {
    const navigate = useNavigate();

    const {
        data: settings,
        isLoading: isLoadingSettings,
        isError: errorSettings
    } = useGetAppSettings({});

    const htmlToReactParser = Parser();

    return (
        <div className="row container">
            <div className="col-lg-4 center-lg login-left">
                <div className="row center-lg" style={{ marginTop: '1rem' }}>
                    <img src={Seal} style={{ width: '12rem', height: '12rem'}} />
                </div>
                <div  className="row center-lg" style={{color: 'red', marginTop: '7rem', fontSize: '2rem' }}>WARNING:</div>
                <div className="row" style={{ marginTop: '1rem'}}>
                    This site is for SENSITIVE processing only. By accessing this system, you are consenting to system monitoring for law enforcement and other purposes. 
                    Unauthorized use or, access to, this system may subject you to criminal and/or civil prosecution and penalties.
                </div>
                <div className="row" style={{ marginTop: '1rem'}}>
                This site is participating in the US Department of Justice (DOJ) Vulnerability Disclosure Policy (VDP) which provides guidelines for the cybersecurity research community and members of the general public. 
                The VDP can be found at this web page. If you wish to report vulnerabilities, please click here to begin the process of reporting or email: Responsible_Disclosure@usdoj.gov
                </div>
            </div>
            <div className="col-lg-8 login-main">
                <div className="row middle-lg" style={{ height: '100%'}}>
                    <div className="row center-lg">
                        <div className="row center-lg login-banner">{ !isLoadingSettings && htmlToReactParser.parse(settings.bannerText) || ''}</div>
                        <div className="row center-lg" style={{ margin: '2rem 0 0.5rem'}}>
                            {/* <a href={`${settings?.ssoUrl}`} target='_self' rel="noopener noreferrer" style={{width: "100%"}}> */}
                                <Button buttonType='login-standard' callback={() => {window.open(`${settings?.ssoUrl}`, "_self")}}>Log In</Button>
                                {/* </a> */}
                        </div>
                        <div className="row center-lg" style={{ margin: '0.5rem 0'}}><a href={ settings?.nurUrl}>Request Access</a></div>
                        <hr style={{ width: '90%'}}/>
                        <div className="row center-lg" style={{ fontSize: '1.5rem', margin: '1rem 0 0.5rem'}}>Need Help?</div>
                        {/* <div className="row center-lg" style={{ margin: '0.5rem 0'}}>For account questions, contact DEA IFA User Access Management (UAM) Team at 1-571-387-4292 or EPIC.Access@dea.gov</div>
                        <div className="row center-lg" style={{ margin: '0.5rem 0 0'}}>For technical difficulties, contact DEA IFA Service Desk: 1-571-362-HELP(4357) or DEA.Service.Desk@usdoj.gov</div> */}
                        {
                            !isLoadingSettings &&
                            settings.helpText.map((text, index) => (
                                <div key={`help-text-${index}`} style={{ margin: '0.25rem 0'}}>{text}</div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;